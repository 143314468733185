<div class="row">
  <div class="col-md-12 order-md-2">
    <div
      class="tab-content"
      id="myTabContent"
    >
      <div
        class="tab-pane fade"
        [ngClass]="{ 'show active': this.activeTab === 'profile' }"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="merge-card">
          <app-card
            [customHeader]="true"
            blockClass="border-top pro-det-edit collapse show"
            *ngIf="isSchool"
            cardTitle="Información del Servicio"
          >
            <h5 class="app-card-header">Información del Servicio</h5>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label font-weight-bolder">Estado</label>
              <div class="col-sm-9 align-items-center d-flex">
                <div class="badge bg-success">
                  {{ 'ACTIVO' }}
                </div>
              </div>
            </div>
          </app-card>
        </div>
        <div class="merge-card">
          <app-card
            [customHeader]="true"
            blockClass="border-top pro-det-edit collapse show"
            *ngIf="!editProfile"
            cardTitle="Datos Personales"
          >
            <h5 class="app-card-header">{{ !isSchool && !isUgel ? 'Datos Personales' : 'Datos de la Institución' }}</h5>
            <ng-container *ngIf="user && user.userDetails && user.userType == 'PERSON'">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">DNI</label>
                <div class="col-sm-9 align-items-center d-flex">
                  {{ user.userDetails.dni }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">Apellidos</label>
                <div class="col-sm-9 align-items-center d-flex">
                  {{ user.userDetails.lastName }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">Nombres</label>
                <div class="col-sm-9 align-items-center d-flex">
                  {{ user.userDetails.firstName }}
                </div>
              </div>
              <!--<div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">Estado</label>
                <div class="col-sm-9 align-items-center d-flex">
                  <div class="badge bg-success">
                    {{ user.status }}
                  </div>
                </div>
              </div>-->
            </ng-container>

            <ng-container *ngIf="user && user.userDetails && user.userType == 'BUSINESS'">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">
                  {{ !isSchool ? 'RUC' : 'Código Modular' }}
                </label>
                <div class="col-sm-9 align-items-center d-flex">
                  {{ user.userDetails.ruc }}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">
                  {{ !isSchool && !isUgel ? 'Razón Social' : 'Nombre' }}
                </label>
                <div class="col-sm-9 align-items-center d-flex">
                  {{ user.userDetails.businessName }}
                </div>
              </div>
              <!--<div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bolder">Estado</label>
                <div class="col-sm-9 align-items-center d-flex">
                  <div class="badge bg-success">
                    {{ user.status }}
                  </div>
                </div>
              </div>-->
            </ng-container>
          </app-card>
        </div>
        <div class="merge-card">
          <app-card
            [customHeader]="true"
            blockClass="border-top pro-det-edit collapse show"
          >
            <div class="app-card-header d-flex justify-content-between">
              <h5 class="mb-0">Información de Contacto</h5>
              <button
                type="button"
                (click)="
                  this.editContact = !this.editContact;
                  this.editContactIcon = this.editContact ? 'icon-x' : 'icon-edit';
                  resetValues()
                "
                class="btn btn-primary btn-sm rounded m-0 float-right"
                data-toggle="collapse"
                data-target=".pro-det-edit"
                aria-expanded="false"
                aria-controls="pro-det-edit-1 pro-det-edit-2"
              >
                <i
                  class="feather"
                  [ngClass]="editContactIcon"
                ></i>
              </button>
            </div>
            <div
              class="alert alert-success"
              *ngIf="registration_successful_message != null"
            >
              {{ registration_successful_message }}
            </div>
            <ng-container *ngIf="user && user.userDetails">
              <ng-container *ngIf="!editContact">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-weight-bolder">Celular 1</label>
                  <div class="col-sm-9 align-items-center d-flex">
                    {{ user.userDetails.cellPhone }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-weight-bolder">Celular 2</label>
                  <div class="col-sm-9 align-items-center d-flex">
                    {{ user.userDetails.cellPhone2 }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-weight-bolder">Teléfono fijo</label>
                  <div class="col-sm-9 align-items-center d-flex">
                    {{ user.userDetails.phone }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label font-weight-bolder">Correo</label>
                  <div class="col-sm-9 align-items-center d-flex">
                    {{ user.userDetails.email }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="editContact && user.userDetails">
              <div
                class="alert alert-danger"
                *ngIf="registration_error_message != null"
              >
                {{ registration_error_message }}
              </div>
              <form
                [formGroup]="form"
                (ngSubmit)="onSubmit()"
              >
                <div class="form-group row my-2">
                  <label
                    class="col-sm-3 col-form-label font-weight-bolder required"
                    for="celular"
                  >
                    Celular 1
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      formControlName="cellphone1"
                      id="celular"
                      name="celular"
                      class="form-control"
                      placeholder="Celular"
                    />
                    <small
                      *ngIf="cellphone1Invalid"
                      class="invalid-feedback d-block"
                    >
                      Ingrese un número de celular válido.
                    </small>
                  </div>
                </div>
                <div class="form-group row my-2">
                  <label
                    class="col-sm-3 col-form-label font-weight-bolder"
                    for="celular"
                  >
                    Celular 2
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      formControlName="cellphone2"
                      id="celular"
                      name="celular"
                      class="form-control"
                      placeholder="Celular"
                    />
                  </div>
                </div>
                <div class="form-group row my-2">
                  <label
                    class="col-sm-3 col-form-label font-weight-bolder"
                    for="celular"
                  >
                    Teléfono fijo
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      formControlName="phone"
                      id="telefono_fijo"
                      name="telefono_fijo"
                      class="form-control"
                      placeholder="Teléfono fijo"
                    />
                  </div>
                </div>
                <div class="form-group row my-2">
                  <label
                    class="col-sm-3 col-form-label font-weight-bolder required"
                    for="email"
                  >
                    Correo
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      formControlName="email"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                    />
                    <small
                      *ngIf="emailInvalid"
                      class="invalid-feedback d-block"
                    >
                      Ingrese un correo válido.
                    </small>
                  </div>
                </div>
                <div class="form-group row my-2">
                  <label class="col-sm-3 col-form-label"></label>
                  <div class="col-sm-9">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      value="Guardar"
                    >
                      <span
                        *ngIf="this.isDelay"
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                      ></span>
                      <span
                        *ngIf="this.isDelay"
                        class="load-text"
                      >
                        Procesando...
                      </span>
                      <span
                        *ngIf="!this.isDelay"
                        class="btn-text"
                      >
                        Guardar
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </ng-container>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>
