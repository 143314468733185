const imagesPaymentPath = 'assets/images/payment/';
const bancaImages = ['bbva', 'bcp', 'interbank', 'scotiabank', 'banbif', 'caja-arequipa', 'banco-pichincha'];
const yapeImages = ['yape'];
const billeteraImages = ['yape', 'plin', 'bbva', 'scotiabank', 'ligo', 'banbif', 'tunki', 'pagoefectivo'];
const tarjetaImages = ['visa', 'mastercard', 'american-express', 'diners-club'];
const getImagePath = (images: string[]) => images.map((img: string) => `${imagesPaymentPath}${img}.png`);
const createPaymentObject = (name: PaymentMethodNames, title: string, helpText: string, images: string[]) => ({
  name,
  title,
  helpText,
  images: getImagePath(images),
});

export type PaymentMethodNames = 'banking' | 'yape' | 'wallet' | 'card';

export type PaymentData = { name: PaymentMethodNames; title: string; images: string[]; helpText: string };

export const imagesPayments: PaymentData[] = [
  /*   createPaymentObject(
    'banking',
    'Banca móvil o internet',
    'Genera tu <b>código de 9 dígitos</b> y paga con <b>PagoEfectivo</b> a traves de:',
    bancaImages,
  ), */
  createPaymentObject('card', 'Tarjeta débito /crédito', 'Aceptamos todas las tarjetas:', tarjetaImages),
  createPaymentObject('wallet', 'Pago con QR (Yape, Plin, etc.)', 'Paga con tu <b>código QR</b> a traves de:', billeteraImages),
  createPaymentObject('yape', 'Yape', 'Paga con <b>código de aprobación</b> a traves de:', yapeImages),
];
