import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss'],
})
export class InstitutionsComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  isDelay: boolean = false;
  institutions: any[] = [];
  institutionsMap: any = {};
  groups: any[] = [];
  groupId!: number;
  nodeId: number | null = null;
  interfaces: any[] = [];
  interface: string | null = null;
  cpuLoad: number = 0.0;
  errorMessage: string = '';
  intervalRouterInterfaces: any;
  channels: any[] = [];
  trafficData!: any;
  institution: any = null;
  userId!: number;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    config: NgbModalConfig,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.getUserGroupsByToken();
  }

  getUserGroupsByToken() {
    this.groups = [];
    this._api.userGroup.getUserGroupsByToken().subscribe({
      next: (resp) => {
        this.groups = resp.data;

        if (this.groups.length > 0) {
          this.groupId = this.groups[0].id;
          this.nodeId = this.groups[0].groupRouter ? this.groups[0].groupRouter.nodeId : null;

          //this.getClients()
          this.loadTable();
        }
      },
      error: (err) => {
        console.warn(err);
      },
    });
  }

  getClients(): void {
    this._api.userGroup.getAllUsersGroupedByUserGroup(this.groupId).subscribe({
      next: (resp) => {
        this.isDelay = false;
        this.institutions = resp.data ? resp.data : [];

        const uniqueIps = this.institutions
          .map((institution) => institution.institutionInterface.ip) // Extraemos todas las IPs
          .filter((ip, index, array) => array.indexOf(ip) === index); // Filtramos para mantener solo las únicas

        this.intervalRouterInterfaces = setInterval(() => {
          uniqueIps.forEach((ip) => {
            if (ip) {
              this._api.router.getInstitutionTrafficInRealTimeByIp(ip, this.groupId).subscribe({
                next: (res) => {
                  this.updateTrafficData(res);
                },
                error: (e) => {
                  this.updateTrafficDataError(ip);
                },
              });
            }
          });
        }, 6000);
      },
      error: (err) => {
        console.warn('error');
      },
    });
  }

  loadTable() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      serverSide: true,
      processing: true,
      deferRender: true,
      orderClasses: false,
      searchDelay: 1200,
      language: {
        processing: `<div class="container">
      <div class="item-1"></div>
      <div class="item-2"></div>
      <div class="item-3"></div>
      <div class="item-4"></div>
      <div class="item-5"></div>
    </div>`,
        search: 'Buscar:',
        lengthMenu: 'Mostrar _MENU_ elementos',
        info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
        infoEmpty: 'Mostrando ningún elemento.',
        infoFiltered: '(filtrado _MAX_ elementos total)',
        infoPostFix: '',
        loadingRecords: 'Cargando registros...',
        zeroRecords: 'No se encontraron registros',
        emptyTable: 'No hay datos disponibles en la tabla',
        paginate: {
          first: 'Primero',
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Último',
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        if (this.intervalRouterInterfaces) {
          clearInterval(this.intervalRouterInterfaces);
        }
        dataTablesParameters.miParametroPersonalizado = 'miValor';
        this.fetchData(dataTablesParameters, callback);
      },
      columns: [
        { data: null, orderable: false },
        { data: null, orderable: false },
        { data: null, orderable: false },
        { data: null, orderable: false },
        { data: null, orderable: false },
        { data: null, orderable: false },
        { data: null, orderable: false },
      ],
      order: [],
    };
  }

  fetchData(dataTablesParameters: any, callback: any): void {
    this._api.userGroup.getUsersGroupedByUserGroup(dataTablesParameters, this.groupId).subscribe({
      next: (resp) => {
        this.isDelay = false;
        this.institutions = resp.data ? resp.data : [];

        const uniqueIps = this.institutions
          .map((institution) => institution.institutionInterface.ip) // Extraemos todas las IPs
          .filter((ip, index, array) => array.indexOf(ip) === index); // Filtramos para mantener solo las únicas

        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: [],
        });

        this.intervalRouterInterfaces = setInterval(() => {
          uniqueIps.forEach((ip) => {
            if (ip) {
              this._api.router.getInstitutionTrafficInRealTimeByIp(ip, this.groupId).subscribe({
                next: (res) => {
                  this.updateTrafficData(res);
                },
                error: (e) => {
                  this.updateTrafficDataError(ip);
                },
              });
            }
          });
        }, 6000);
      },
      error: (err) => {
        console.warn('error');
      },
    });
  }

  reloadData(): void {
    $('#institutionsTable').DataTable().ajax.reload(undefined, false);
  }

  trackById(index: number, item: any): string {
    return item.id;
  }

  openRouter(ip: string) {
    if (ip) {
      window.open(`http://${ip}`, '_blank', 'noopener,noreferrer');
    }
  }

  updateTrafficData(newTrafficData: any[]) {
    // Recorrer cada elemento en el array institutions
    this.institutions.forEach((institution) => {
      // Buscar si existe un elemento en newTrafficData que coincida con el id de institutionInterface
      const matchingTraffic = newTrafficData.find(
        (trafficItem) => trafficItem.id === institution.institutionInterface.id,
      );

      // Si existe una coincidencia, agregar la propiedad trafficData con el objeto de tráfico encontrado
      if (matchingTraffic) {
        institution.trafficData = matchingTraffic;

        if (this.institution && this.institution.institutionInterface.id === matchingTraffic.id) {
          this.trafficData = this.getTraffic(this.institution.id);
        }
      }
    });
  }

  updateTrafficDataError(ip: string) {
    this.institutions.forEach((institution) => {
      if (institution.institutionInterface.ip === ip) {
        institution.trafficData = null;
      }

      if (
        this.institution &&
        this.institution.id === institution.id &&
        this.institution.institutionInterface.ip === ip
      ) {
        this.trafficData = this.getTraffic(this.institution.id);
      }
    });
  }

  openDetails(content: any, institution: any) {
    this.institution = institution;
    this.userId = this.institution.id;

    this.trafficData = this.getTraffic(institution.id);
    this._modalService.open(content, {
      centered: true,
      size: 'xl',
    });
  }

  private getTraffic(userId: number) {
    const data = {
      tx: 0,
      rx: 0,
      textTx: '',
      textRx: '',
      status: 'INACTIVO'
    };

    const item = this.institutions.find((item) => item.id === userId);

    if (!item || !item.trafficData) {
      return data;
    } else {
      data.tx = +String(item.trafficData.tx).split(' ')[0];
      data.rx = +String(item.trafficData.rx).split(' ')[0];
      data.textTx = item.trafficData.tx;
      data.textRx = item.trafficData.rx;
      data.status = 'ACTIVO'
    }

    return data;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.intervalRouterInterfaces) {
      clearInterval(this.intervalRouterInterfaces);
    }
  }
}
