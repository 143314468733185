import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgCulqiService } from '../../services/ng-culqi.service';
import { ActivatedRoute } from '@angular/router';
import { concatMap, tap, takeUntil, retry } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService as UserService } from '../../services/user.service';
import { imagesPayments, PaymentData } from './data';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { PaymentMethodDetail } from 'src/app/models/api-response/payment-method';
import { ChargeBody, ReceiptDetail, ReceiptItem } from 'src/app/models/api-response/receipt';
import { ToastService } from 'src/app/theme/shared/components/toast/toast.service';
import { UserIdentity } from 'src/app/models/api-response/user';
import { ApiService } from 'src/app/services/api/api.service';
import { Utils } from 'src/app/utils';
import { NGXLogger } from 'ngx-logger';

type ReceiptPay = {
  recibo_cliente_id: number;
  Comprobante_detalle_id: null;
  numero_comprobante: null;
  fecha_vencimiento_comprobante: null;
  fecha_pago: string;
  forma_pago_detalle_id: number;
  comision: number;
  codigo_operacion: number;
  monto_total_pago: number;
  estado: string;
};

@Component({
  selector: 'app-culqi',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss'],
})
export class PaymentGatewayComponent implements OnInit {
  @ViewChild('alertSwal')
  alertSwal!: SwalComponent;

  identity: UserIdentity;
  receiptDetail: ReceiptDetail; //{} recibo actual que pagará usuario
  paymentMethodDetail: PaymentMethodDetail; //{} detalles de la pasarela de pagos
  receipt: { estado: string }; // {} datos para actualizar recibo_cliente
  receiptPay: ReceiptPay; // {} datos para actualizar recibo_cliente_pago
  receiptId: number; // id del recibo a pagar
  paymentMethodDetailId = 1; // id de la forma de pago en general
  totalComision: number;
  receiptAmount: number;
  totalAmount: number;
  productDescription: string;
  exchangeRate = 4;
  receiptItemList: ReceiptItem[] = [];

  responseStatus: string | null = null;
  paymentStatus: string | null = null;
  paymentRegistrationStatus: string | null = null;
  userErrorMessage: string;

  tokenCulqi: {
    email: string;
    id: string;
    active: boolean;
    card_number: string;
    creation_date: number;
    last_four: string;
    object: string;
    type: string;
    client: {
      browser: string;
device_fingerprint: string;
device_type: string;
ip: string;
ip_country: string;
ip_country_code: string;
    }
  };
  body?: Partial<ChargeBody>;

  private ngUnsubscribe = new Subject(); // describir

  paymentSelected?: PaymentData;
  paymentTypes = imagesPayments.map((e) => ({ value: e.name, text: e.title }));

  constructor(
    public _user: UserService,
    private _culqi: NgCulqiService,
    private _route: ActivatedRoute,
    private _toast: ToastService,
    private _api: ApiService,
    private _log: NGXLogger,
  ) {
    this.identity = this._user.getIdentity();
  }

  // Recibe la variable de culqi y lo procesa para realizar la transacción.
  @HostListener('document:payment_event', ['$event'])
  onPaymentEventCustom(data: CustomEvent) {
    if (data.detail.token) {
      if (this.body != null) {
        this.tokenCulqi = data.detail.token; // objeto de respuesta al cargo
        this.body.email = this.tokenCulqi.email;
        this.body.source_id = this.tokenCulqi.id;

        if(this.body.antifraud_details) {
          this.body.antifraud_details.country_code = this.tokenCulqi.client.ip_country_code;
        }

        this._culqi.close();
        this.paymentStatus = 'loading';

        this.charges(this.body); // crear cargo a la tarjeta del cliente
      }
    }

    this.body = undefined;
  }

  ngOnInit() {
    this.generatePaymentReceipt();
    this._culqi.initCulqi();
    this._culqi
      .getExchangeRate()
      .pipe(retry(3))
      .subscribe((res) => {
        this.exchangeRate = res.data.venta;
      });
  }

  generatePaymentReceipt() {
    this._route.params
      .pipe(
        tap((params) => {
          this.receiptId = +params['recibo'];
        }),

        concatMap((_) => this._api.customerReceipt.getClientReceiptDetail(this.receiptId)),
        tap((res) => {
          this.receiptDetail = res.recibo_cliente;
        }),

        concatMap((_) => this._api.paymentMethodDetail.getDetail(this.paymentMethodDetailId)),
        tap((res) => {
          // API check
          this.paymentMethodDetail = res.forma_pago_detalle;
        }),

        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.receiptDetail.recibo_cliente_item.forEach((item) => {
            if (item.estado !== 'ELIMINADO') this.receiptItemList.push(item);
          });
          this.productDescription = `Recibo de servicios del mes de ${Utils.date.getMonth(this.receiptDetail.mes)}`;
          this.receiptAmount = Number(this.receiptDetail.monto_total);

          if (this.receiptAmount > 68) {
            this.totalComision =
              Number(0.052144 * this.receiptAmount) +
              Number(1.24153 * Number(this.paymentMethodDetail.comision_empresa_fija) * this.exchangeRate) -
              Number(4.734648);
          } else {
            this.totalComision = 0;
          }

          this.totalAmount = this.receiptAmount + Number(this.totalComision.toFixed(2));
          this.body = {
            amount: Number((this.totalAmount * 100).toFixed(2)),
            currency_code: 'PEN',
            email: undefined,
            source_id: undefined,
            antifraud_details: {
              address: 'No especificado',
              address_city: 'No especificado',
              country_code: '',
              first_name: this.identity.nombres,
              last_name: this.identity.name.length == 11 ? this.identity.nombres : this.identity.apellidos,
              phone_number: this.identity.celular,
            },
            metadata: {
              user_id: this.identity.sub,
              dni: this.identity.name,
              month: this.receiptDetail.mes,
              receipt_id: this.receiptDetail.id,
            },
          };

          this.responseStatus = 'success';
          document.querySelector('body')?.classList.add('modal-open');
        },
        error: (error) => {
          this.responseStatus = 'error';
          this._log.error(error);
        },
      });
  }

  payment() {
    if (!this.paymentSelected) {
      this.alertSwal.fire();
      return;
    }

    if (this.paymentSelected.name === 'card' || this.paymentSelected.name === 'yape') {
      const amount = Number((this.totalAmount * 100).toFixed(2));

      this._culqi.payOrder(this.productDescription, amount, this.paymentSelected.name, '');
    } else {
      this._culqi
        .createOrder(
          this.productDescription,
          this.totalAmount,
          this.identity,
          this.receiptDetail,
          this.paymentSelected.name,
        )
        .subscribe((res) => {
          if (this.paymentSelected)
            this._culqi.payOrder(this.productDescription, res.amount, this.paymentSelected.name, res.id);
        });
    }
  }

  charges(body: Partial<ChargeBody>) {
    this._culqi
      .charges(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.receiptPay = {
            recibo_cliente_id: this.receiptDetail.id,
            Comprobante_detalle_id: null,
            numero_comprobante: null,
            fecha_vencimiento_comprobante: null,
            fecha_pago: formatDate(res.creation_date, 'yyyy-MM-dd hh:mm:ss', 'en-US'),
            forma_pago_detalle_id: this.paymentMethodDetailId,
            comision: Number(this.totalComision.toFixed(2)),
            codigo_operacion: +res.id,
            monto_total_pago: this.totalAmount,
            estado: 'REGISTRADO',
          };

          this.receipt = { estado: 'PAGADO' };

          this.paymentStatus = 'success';

          document.querySelector('body')?.classList.remove('modal-open');

          this.updateReciboClientePago(this.receiptPay, this.receipt);
        },
        error: (error) => {
          this._log.error(error);
          this._toast.show('Error Culqi', error.error.user_message, 'danger', 10000);
          this.userErrorMessage = 'error';
          this.paymentStatus = 'error';
          document.querySelector('body')?.classList.remove('modal-open');
        },
      });
  }

  updateReciboClientePago(receiptPay: ReceiptPay, receipt: { estado: string }) {
    this._api.customerReceipt
      .createPay(receiptPay)
      .pipe(
        concatMap((_) => this._api.customerReceipt.update(receipt, this.receiptId)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.paymentRegistrationStatus = 'success';
        },
        error: (error) => {
          this.paymentRegistrationStatus = 'error';
          this._log.error(error);
        },
      });
  }
  changeSelect(paymentType: string) {
    this.paymentSelected = imagesPayments.find((e) => e.name === paymentType);
  }
}
